import React from 'react';

import { Container, useMediaQuery, useTheme } from '@mui/material';
import { TAGBackground } from '@aspendental/themes';

import { Maybe, TemplateWidth, TemplateHeight, ContentfulBackground } from '@/types';
import { reverseLookupContentfulBgColor } from '@/utils/reverseLookupContentfulBgColor';
import { resolveContentfulBgColor } from '@/utils/resolveContentfulBgColor';

import type { ContainerProps, SxProps, BoxProps } from '@mui/material';

interface ITemplateContainer extends ContainerProps {
	dataTestId?: string;
	id?: string;
	containerSx?: SxProps;
	children: React.ReactNode;
	containerProps?: Partial<ContainerProps & Omit<ContainerProps, 'sx' | 'disableGutters'>>;
	backgroundColor?: Maybe<ContentfulBackground | TAGBackground>;
	contentfulBackgroundColor?: Maybe<string>;
	component?: BoxProps['component'];
	templateWidth?: TemplateWidth;
	templateHeight?: TemplateHeight;
	maxWidth?: ContainerProps['maxWidth'];
}

export default function TemplateContainer({
	children,
	backgroundColor,
	contentfulBackgroundColor,
	dataTestId = 'section_template_container',
	id = 'template-container-id',
	containerSx = {},
	containerProps = {},
	disableGutters = true,
	templateWidth = 'Full',
	templateHeight = 'Standard',
	component = 'section',
	maxWidth,
}: Readonly<ITemplateContainer>) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const getBgColor = (backgroundColor: TAGBackground, contentfulBackgroundColor: string) => {
		if (contentfulBackgroundColor) return contentfulBackgroundColor;

		if (backgroundColor) {
			const contentfulBgColor = reverseLookupContentfulBgColor(`backgrounds.${backgroundColor}`);
			const bgColor = resolveContentfulBgColor(contentfulBgColor, theme);
			return bgColor;
		}

		return 'white';
	};

	const styles = {
		width: templateWidth === 'Inset' ? '90%' : '100%',
		height: templateHeight === 'Large' && !isSmallScreen ? 'calc(100vh - 120px)' : 'auto',
		maxWidth: { md: '1920px' },
		margin: '0 auto',
		background: getBgColor(backgroundColor as TAGBackground, contentfulBackgroundColor as string),
		...containerSx,
	};

	return (
		<Container
			maxWidth={maxWidth}
			sx={styles}
			component={component}
			data-test-id={dataTestId}
			id={id}
			disableGutters={disableGutters}
			{...containerProps}
		>
			{children}
		</Container>
	);
}
